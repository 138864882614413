

import React, { Component } from "react";
import cx from 'classnames';
import Styles from '../assets/styles/howItWorksSteps.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import newslogos from "../assets/images/newslogos.svg";
import starsOverall from "../assets/images/stars-overall.svg"
import "../assets/styles/rating-section.css?ver=0.1";
import "../assets/styles/custom-button.css?ver=0.1";



library.add(faCheck);

class HashchingRatings extends Component {

    constructor(props) {
      super(props);
      this.state = {
        // circularAnimation: '',
        // allContents: '', 
      }
    }
 
    render() { 
        return (
            <section className={"sectionPadding relative-section inner-sections"}>
            <div className={"container"}>
              
              <div className={"row text-center"}>
                <div className={"col-md-12 text-section"}>
                  <p className={"text-center"}>
                     <img src={starsOverall} alt="5 Star ratings" />
                  </p>
                  <h3 className={"orange"}>
                    <strong>Rated 4.7 stars</strong>
                  </h3>
                  <h6 className={""}><strong>by 3,900+ happy customers</strong></h6>
                </div>
                <div className={"col-md-12 mx-auto reviews-tile"}>
                  <div className={"row"}>
                    <div className={"col-md-4 col-sm-6"}>
                      <h4><strong>Highly satisfied!</strong></h4>
                      <p>Sunny was fantastic to work with. He is very knowledgeable and always available to talk through any aspects of the process. Very happy with the...</p>
                      <p className={"review-author"}><strong>Vin Ching,</strong> NSW</p>
                      <p>
                        <a href="/hashching-reviews/user-reviews">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          </a>
                      </p>
                    </div>
                    <div className={"col-md-4 col-sm-6"}>
                      <h4><strong>Great deal!</strong></h4>
                      <p>It was a great deal with Alisa and she helped me a lot to get my Home loan without any issues. </p>
                      <p className={"review-author"}><strong>Naresh,</strong> VIC</p>
                      <p>
                        <a href="/hashching-reviews/user-reviews">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                            </svg>
                          </a>
                      </p>
                    </div>
                    <div className={"col-md-4 col-sm-6"}>
                      <h4><strong>Best experience with Martin!</strong></h4>
                      <p>Martin was fantastic and is very knowledgable with all the products he has to offer. He goes out his way to explain the process and what best suits his clients.</p>
                      <p className={"review-author"}><strong>BILL POPHRISTOFF,</strong> NSW</p>
                      <p>
                        <a href="/hashching-reviews/user-reviews">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="20" viewBox="0 0 20.741 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.418,2.972a1,1,0,0,1,1.9,0L12.6,6.944a1,1,0,0,0,.952.695H17.7a1,1,0,0,1,.593,1.805l-3.38,2.49a1,1,0,0,0-.359,1.11l1.286,4.012a1,1,0,0,1-1.545,1.11l-3.327-2.451a1,1,0,0,0-1.186,0L6.45,18.167A1,1,0,0,1,4.9,17.056L6.19,13.045a1,1,0,0,0-.359-1.11L2.45,9.444a1,1,0,0,1,.593-1.805H7.193a1,1,0,0,0,.952-.695Z" fill="#fc0" />
                            </svg>
                          </a>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                    {/* <div className={"col-sm-12 text-center"}>
                      <a href="/hashching-reviews/user-reviews" className={"btn-primary-3D"}> &nbsp; View more... &nbsp; </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
  };
};

export default HashchingRatings;
