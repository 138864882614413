import React, { Component } from "react";
import cx from 'classnames';
import styles from "../assets/styles/whyWeAreBetterSection.module.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import carLoanImg from "../assets/images/v4/consumer-benefits/carFinanceImage.jpg"; 
import Slider from "react-slick";
import logoSection from "../assets/images/logo.png";
library.add(faTimes, faCheck)

class WhyWeAreBetter extends Component {
    constructor() {
        super();
        this.state = {

        };
    }


    textOrCross(value) {
        if (value == 'cross') {
            return <i className={styles.fa} aria-hidden="true"><FontAwesomeIcon icon={faTimes} /></i>;
        }
        else if (value == 'tick') {
            return <i className={styles.fa} aria-hidden="true"><FontAwesomeIcon icon={faCheck} /></i>
        }
        else {
            return value;
        }
    }



    render() {
        const content = this.props.content
        const LeadsCount = this.props.LeadsContent || '46,407';
        // console.log(LeadsCount);

        if (!content) {
            return null;
        }

        var settings = {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            lazyLoad: 'ondemand',
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            // responsive: [ 
            // {                
            //     settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1,
            //         dots: false,
            //         autoplay: true,
            //         autoplaySpeed: 1000 * 15
            //     }
            // }
            // ]
        };

        return (
            <div className={cx(styles.whywearebetterSection)}>
                <div className={cx(styles.bannerSection)}>
                    {/* <Slider {...settings} className={cx('col-12 ratingSlider', styles.reviewratingslider)}> */}
                    {/* <div className={"container"}>
                            <h4>Sydney-based and Australia’s first online marketplace for home loans.</h4>
                        </div> */}                    

                    {/* <div className={cx(styles.sliderItem, styles.smeSlider)}>
                        <div className={styles.sectionOverlay}>
                            <div className={"container"}>
                                <div className={"col-lg-8 col-md-10"}>
                                    <div className={styles.bannerContent}>
                                        <h6>#SME Loans</h6>
                                        <h2 className={styles.sectionTitle}>
                                            Unsecured &amp; secured business loans.
                                        </h2>
                                        <p>We’ve funded hundreds of Australian small businesses to date, across industries such as hospitality, franchises, retailers, professional services, trades and labour, creative agencies, health and beauty services and much more.</p>
                                        <a className={"btn btn-primary"} href="/business-loans">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className={cx(styles.sliderItem, styles.carSlider)}>
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-lg-7 col-md-6 col-12 text-center"}>
                                    <img src={carLoanImg} />
                                </div>
                                <div className={"col-lg-5 col-md-6 col-12"}>
                                    <div className={styles.bannerContent}>
                                        <h2 className={styles.sectionTitle}>
                                            Simple &amp; Easy Car Finance
                                    </h2>
                                        <p>Get into your new wheels today with our tailored, super-low rate car loan solutions for your personal or business need.</p>
                                        <a className={"btn btn-primary"} href="/car-finance">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cx(styles.sliderItem, styles.assetSlider)}>
                        <div className={styles.sectionOverlay}>
                            <div className={"container"}>
                                <div className={"col-lg-8 col-md-10"}>
                                    <div className={styles.bannerContent}>
                                        <h2 className={styles.sectionTitle}>
                                            Assets &amp; Equipment Finance, not a problem.
                                        </h2>
                                        <p>Just started a new business? From heavy machinery to video cameras we can help you get all the equipment you need to get you going.</p>
                                        <a className={"btn btn-primary"} href="/assets-equipment-finance">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx("text-center", styles.sliderItem, styles.conveyancingSlider)}>
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <h6>#Conveyancing</h6>
                                    <h2>Fully licensed solicitors within minutes!</h2>
                                    <p>Our conveyancing service enables you  to connect with certified solicitors “within minutes” after your home loan application is lodged by a broker.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.sliderItem, styles.hashconnectSlider)}>
                        <div className={styles.sectionOverlay}>
                            <div className={"container"}>
                                <div className={"col-sm-12 text-center"}>
                                    <div className={styles.bannerContent}>
                                        <h6 className={"black"}>MOVING TO A NEW HOUSE?</h6>
                                        <h2 className={styles.sectionTitle}>
                                            Get connected in just one call.
                                            </h2>
                                        <p>Moving-homes, made easy. Connect all major utilities in just one call. Its a FREE service for you, your friends/family and clients.</p>
                                        <a href="/request-for-hashconnect" className={"btn btn-primary"}>Submit a Request</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* </Slider> */}
                </div>
                {/* <div className={'container'}>
                <div className={'row'}>
                    
                    <div className={cx('col-xl-12', 'col-lg-12', 'col-md-12', 'col-sm-12', 'col-12', styles.titles)}>
                        <h2 className={styles.sectionTitle}>{content.label_heading.value}</h2>
                        <h3 className={styles.sectionSubtitle}>{content.label_description.value} <br /> {content.label_descriptionpostfix.value}</h3>
                    </div>
                    <div className={cx('col-xl-12', 'col-lg-12', 'col-md-12', 'col-sm-12', 'col-12', styles.whyWeAreBetterContnt, 'text-center')}>
                        <ul className={styles.listingcontnt}>
                            <li className={styles.leftListcontnt}>
                                <header className={styles.listingHeader}>
                                </header>
                                <div className={styles.listingBody}>
                                    <ul className={styles.listingBodycontnt}>
                                        <li style={{ paddingTop: '15px' }}>{content.label_feature1.value}</li>
                                        <li style={{ paddingTop: '15px' }}>{content.label_feature2.value}</li>
                                        <li style={{ paddingTop: '15px' }}>{content.label_feature3.value} <br /> {content.label_feature3postfix.value}</li>
                                        <li style={{ paddingTop: '15px' }}>{content.label_feature4.value} <br /> {content.label_feature4postfix.value}</li>
                                        <li>{content.label_feature5.value} <br /> {content.label_feature5postfix.value}</li>
                                        <li style={{ paddingTop: '15px' }}>{content.label_feature6.value} <br /> {content.label_feature6postfix.value}</li>
                                    </ul>
                                </div>
                            </li>
                            <li className={styles.hashingListing}>
                                <header className={styles.listingHeader}>
                                    <h2 className={styles.listingLogo}>
                                        <img src={logoSection} alt="HFG Marketplace home loans" />
                                    </h2>

                                </header>
                                <div className={styles.listingBody}>
                                    <ul className={styles.listingBodycontnt}>
                                        <li>{this.textOrCross(content.label_feature1hashching.value)}</li>
                                        <li>{this.textOrCross(content.label_feature2hashching.value)}</li>
                                        <li>{this.textOrCross(content.label_feature3hashching.value)}</li>
                                        <li>{this.textOrCross(content.label_feature4hashching.value)}</li>
                                        <li>{this.textOrCross(content.label_feature5hashching.value)}</li>
                                        <li>{this.textOrCross(content.label_feature6hashching.value)}</li>
                                    </ul>
                                </div>
                            </li>
                            <li className={styles.onlineBrokrsListing}>
                                <header className={styles.listingHeader}>
                                    <h2>Online brokers/<br />Comparison websites</h2>
                                </header>
                                <div className={styles.listingBody}>
                                    <ul className={styles.listingBodycontnt}>
                                        <li>{this.textOrCross(content.label_feature1otheronlinewebsite.value)}</li>
                                        <li>{this.textOrCross(content.label_feature2otheronlinewebsite.value)}</li>
                                        <li>{this.textOrCross(content.label_feature3otheronlinewebsite.value)}</li>
                                        <li>{this.textOrCross(content.label_feature4otheronlinewebsite.value)}</li>
                                        <li>{this.textOrCross(content.label_feature5otheronlinewebsite.value)}</li>
                                        <li>{this.textOrCross(content.label_feature6otheronlinewebsite.value)}</li>
                                    </ul>
                                </div>
                            </li>
                            <li className={styles.typiclBrokrsListing}>
                                <header className={styles.listingHeader}>
                                    <h2>Typical<br />banks &amp; brokers</h2>
                                </header>
                                <div className={styles.listingBody}>
                                    <ul className={styles.listingBodycontnt}>
                                        <li>{this.textOrCross(content.label_feature1banksbrokers.value)} <br /> {this.textOrCross(content.label_feature1banksbrokerspostfix.value)}</li>
                                        <li>{this.textOrCross(content.label_feature2banksbrokers.value)} <br /> {this.textOrCross(content.label_feature2banksbrokerspostfix.value)}</li>
                                        <li>{this.textOrCross(content.label_feature3banksbrokers.value)} <br /> {this.textOrCross(content.label_feature3banksbrokerspostfix.value)}</li>
                                        <li>{this.textOrCross(content.label_feature4banksbrokers.value)}</li>
                                        <li>{this.textOrCross(content.label_feature5banksbrokers.value)}</li>
                                        <li>{this.textOrCross(content.label_feature6banksbrokers.value)}</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className={cx('col-xl-12', 'col-lg-12', 'col-md-12', 'col-sm-12', 'col-12', styles.whyweBettrAccordian)} id="whywebettr_accordion">
                        <div className={styles.panel}>
                            <div className={styles.panelHeading}>
                                <h4 className={styles.panelTitle}>
                                    <a className={styles.accordionToggle} data-toggle="collapse" aria-expanded="true" data-parent="#whywebettr_accordion" href="javascript:void(0)" onClick={this.onclickWhyWeAreBetteraccordion1}>
                                        <span className={styles.hedingTxt}>{content.label_feature1.value}</span>
                                        <span className={styles.hedingArows}>
                                            <span className={styles.hedingArowUp} style={{ display: 'none' }}></span>
                                            <span className={styles.hedingArowDown}></span>
                                        </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="whywebettr_accordion1" className={cx(styles.panelCollapse, styles.in, styles.show, styles[this.state.WhyWeAreBetteraccordion1])}>
                                <div className={styles.panelBody}>
                                    <ul>
                                        <li className={styles.hashingListing}>
                                            <header className={styles.listingHeader}>

                                                <h2 className={styles.listingLogo}>
                                                    <img src={logoSection} alt="HFG Marketplace home loans" />
                                                </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature1hashching.value)}</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.onlineBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Online brokers/<br />Comparison <br />websites </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature1otheronlinewebsite.value)}</li>


                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.typiclBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Typical<br />banks &amp; <br />brokers</h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature1banksbrokers.value)} <br /> {this.textOrCross(content.label_feature1banksbrokerspostfix.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={styles.panel}>
                            <div className={styles.panelHeading}>
                                <h4 className={styles.panelTitle}>
                                    <a className={styles.accordionToggle} data-toggle="collapse" aria-expanded="true" data-parent="#whywebettr_accordion" href="javascript:void(0)" onClick={this.onclickWhyWeAreBetteraccordion2}>
                                        <span className={styles.hedingTxt}>{content.label_feature2.value}</span>
                                        <span className={styles.hedingArows}>
                                            <span className={styles.hedingArowUp} style={{ display: 'none' }}></span>
                                            <span className={styles.hedingArowDown}></span>
                                        </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="whywebettr_accordion1" className={cx(styles.panelCollapse, styles.in, styles.show, styles[this.state.WhyWeAreBetteraccordion2])}>
                                <div className={styles.panelBody}>
                                    <ul>
                                        <li className={styles.hashingListing}>
                                            <header className={styles.listingHeader}>

                                                <h2 className={styles.listingLogo}>
                                                    <img src={logoSection} alt="HFG Marketplace home loans" />
                                                </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature2hashching.value)}</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.onlineBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Online brokers/<br />Comparison <br />websites </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature2otheronlinewebsite.value)}</li>


                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.typiclBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Typical<br />banks &amp; <br />brokers</h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature2banksbrokers.value)} <br /> {this.textOrCross(content.label_feature2banksbrokerspostfix.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className={styles.panel}>
                            <div className={styles.panelHeading}>
                                <h4 className={styles.panelTitle}>
                                    <a className={styles.accordionToggle} data-toggle="collapse" aria-expanded="true" data-parent="#whywebettr_accordion" href="javascript:void(0)" onClick={this.onclickWhyWeAreBetteraccordion3}>
                                        <span className={styles.hedingTxt}>{content.label_feature3.value} <br /> {content.label_feature3postfix.value}</span>
                                        <span className={styles.hedingArows}>
                                            <span className={styles.hedingArowUp} style={{ display: 'none' }}></span>
                                            <span className={styles.hedingArowDown}></span>
                                        </span>


                                    </a>
                                </h4>
                            </div>
                            <div id="whywebettr_accordion1" className={cx(styles.panelCollapse, styles.in, styles.show, styles[this.state.WhyWeAreBetteraccordion3])}>
                                <div className={styles.panelBody}>
                                    <ul>
                                        <li className={styles.hashingListing}>
                                            <header className={styles.listingHeader}>

                                                <h2 className={styles.listingLogo}>
                                                    <img src={logoSection} alt="HFG Marketplace home loans" />
                                                </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature3hashching.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.onlineBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Online brokers/<br />Comparison <br />websites </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature3otheronlinewebsite.value)}</li>


                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.typiclBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Typical<br />banks &amp; <br />brokers</h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature3banksbrokers.value)} <br /> {this.textOrCross(content.label_feature3banksbrokerspostfix.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={styles.panel}>
                            <div className={styles.panelHeading}>
                                <h4 className={styles.panelTitle}>
                                    <a className={styles.accordionToggle} data-toggle="collapse" aria-expanded="true" data-parent="#whywebettr_accordion" href="javascript:void(0)" onClick={this.onclickWhyWeAreBetteraccordion4}>
                                        <span className={styles.hedingTxt}>{content.label_feature4.value} <br /> {content.label_feature4postfix.value}</span>
                                        <span className={styles.hedingArows}>
                                            <span className={styles.hedingArowUp} style={{ display: 'none' }}></span>
                                            <span className={styles.hedingArowDown}></span>
                                        </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="whywebettr_accordion1" className={cx(styles.panelCollapse, styles.in, styles.show, styles[this.state.WhyWeAreBetteraccordion4])}>
                                <div className={styles.panelBody}>
                                    <ul>
                                        <li className={styles.hashingListing}>
                                            <header className={styles.listingHeader}>

                                                <h2 className={styles.listingLogo}>
                                                    <img src={logoSection} alt="HFG Marketplace home loans" />
                                                </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature4hashching.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.onlineBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Online brokers/<br />Comparison <br />websites </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature4otheronlinewebsite.value)}</li>


                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.typiclBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Typical<br />banks &amp; <br />brokers</h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature4banksbrokers.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={styles.panel}>
                            <div className={styles.panelHeading}>
                                <h4 className={styles.panelTitle}>
                                    <a className={styles.accordionToggle} data-toggle="collapse" aria-expanded="true" data-parent="#whywebettr_accordion" href="javascript:void(0)" onClick={this.onclickWhyWeAreBetteraccordion5}>
                                        <span className={styles.hedingTxt}>{content.label_feature5.value} <br /> {content.label_feature5postfix.value}</span>
                                        <span className={styles.hedingArows}>
                                            <span className={styles.hedingArowUp} style={{ display: 'none' }}></span>
                                            <span className={styles.hedingArowDown}></span>
                                        </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="whywebettr_accordion1" className={cx(styles.panelCollapse, styles.in, styles.show, styles[this.state.WhyWeAreBetteraccordion5])}>
                                <div className={styles.panelBody}>
                                    <ul>
                                        <li className={styles.hashingListing}>
                                            <header className={styles.listingHeader}>

                                                <h2 className={styles.listingLogo}>
                                                    <img src={logoSection} alt="HFG Marketplace home loans" />
                                                </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature5hashching.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.onlineBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Online brokers/<br />Comparison <br />websites </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature5otheronlinewebsite.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.typiclBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Typical<br />banks &amp; <br />brokers</h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature5banksbrokers.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={styles.panel}>
                            <div className={styles.panelHeading}>
                                <h4 className={styles.panelTitle}>
                                    <a className={styles.accordionToggle} data-toggle="collapse" aria-expanded="true" data-parent="#whywebettr_accordion" href="javascript:void(0)" onClick={this.onclickWhyWeAreBetteraccordion6}>
                                        <span className={styles.hedingTxt}>{content.label_feature6.value} <br /> {content.label_feature6postfix.value}</span>
                                        <span className={styles.hedingArows}>
                                            <span className={styles.hedingArowUp} style={{ display: 'none' }}></span>
                                            <span className={styles.hedingArowDown}></span>
                                        </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="whywebettr_accordion1" className={cx(styles.panelCollapse, styles.in, styles.show, styles[this.state.WhyWeAreBetteraccordion6])}>
                                <div className={styles.panelBody}>
                                    <ul>
                                        <li className={styles.hashingListing}>
                                            <header className={styles.listingHeader}>

                                                <h2 className={styles.listingLogo}>
                                                    <img src={logoSection} alt="HFG Marketplace home loans" />
                                                </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature6hashching.value)}</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.onlineBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Online brokers/<br />Comparison <br />websites </h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature6otheronlinewebsite.value)}</li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li className={styles.typiclBrokrsListing}>
                                            <header className={styles.listingHeader}>
                                                <h2>Typical<br />banks &amp; <br />brokers</h2>
                                            </header>
                                            <div className={styles.listingBody}>
                                                <ul className={styles.listingBodycontnt}>
                                                    <li>{this.textOrCross(content.label_feature6banksbrokers.value)}</li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>  

                </div>
            </div>*/}
            </div>
        )
    }
};

export default WhyWeAreBetter;