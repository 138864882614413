

import React, { Component } from "react";
import cx from 'classnames';
import Styles from '../assets/styles/howItWorksSteps.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import newslogos from "../assets/images/newslogos.svg";
import "../assets/styles/about-section.css?ver=0.1";
library.add(faCheck);

class AboutHashching extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // circularAnimation: '',
      // allContents: '', 
    }
  }

  render() {
    return (

      <section className={"loanJournySection text-center sectionPadding"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <h3>Manage your entire loan journey from <nobr>one place</nobr></h3>
              <p>
                HFG Marketplace works with a wide range of lenders and brokers throughout Australia to guarantee that no matter what type of loan you’re looking for, you’ll find the best possible deal.
              </p>
              <p>
                When you work with HFG Marketplace you gain access to free property and credit reports, loan calculators and tools, and exclusive special offers on products such as conveyancing and insurance; all provided through your free-to-use HFG Marketplace account.
              </p>
            </div>
          </div>
        </div>
      </section>

    );
  };
};

export default AboutHashching;
