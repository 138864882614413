import React from 'react';
import cx from 'classnames';
import Styles from '../assets/styles/bankersStyles.module.css';

import virginMoney from "../assets/images/v4/banks/virgin-money.png";
import bankwest from "../assets/images/v4/banks/bankwest.svg";
import anz from "../assets/images/v4/banks/anz.svg";
import stgeorge from "../assets/images/v4/banks/stgeorge.svg";
import ing from "../assets/images/v4/banks/ing.svg";
import citibank from "../assets/images/v4/banks/citibank.svg";
import commbank from "../assets/images/v4/banks/commbank.svg";
import pepper from "../assets/images/v4/banks/pepper.svg";
import westpac from "../assets/images/v4/banks/westpac.svg";
import nab from "../assets/images/v4/banks/nab.svg";
import bankofsydney from "../assets/images/v4/banks/bankofsydney.svg";
import suncorp from "../assets/images/v4/banks/suncorp.png";
import npbs from "../assets/images/v4/banks/npbs.svg";

const Bankers = props => {

  return (
    <section className={cx(Styles.bankSection, "text-center section cmargint cpaddingt")}>
      <div className={"container"}>
        {/* <h5 className={cx("strong", Styles.sectionTitle)}>Our experienced brokers will search the entire market to find you the best possible deal</h5> */}
        <h5 className={cx("strong", Styles.sectionTitle)}>Unlock the best deals tailored to your needs</h5>
        <h6 className={cx(Styles.orange, Styles.sectionSubTitle)}>Access top rates and products from over 100+ lenders and growing.</h6>        
        <div className={Styles.bankLogos}>
          <img src={virginMoney} />
          <img src={bankwest} className={Styles.bankwest} />
          <img src={anz} className={Styles.anz} />
          <img src={stgeorge} className={Styles.stgeorge} />
          <img src={ing} className={Styles.ing} />
          <img src={citibank} className={Styles.citibank} />
          <img src={commbank} className={Styles.commbank} />
          <img src={pepper} className={Styles.pepper} />
          <img src={westpac} className={Styles.westpac} />
          <img src={nab} className={Styles.nab} />
          <img src={bankofsydney} className={Styles.nab} />
          <img src={suncorp} className={Styles.suncorp} />
          <img src={npbs} className={Styles.nab} />          
        </div>
        {/* <div className={"mt-5"}>            
          <a href="/hashching-home-loan-deals" className={"btn btn-primary btn-lg"}>View Deals</a>
        </div> */}
      </div>
    </section>
  );
};

export default Bankers;