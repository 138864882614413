import React from 'react';
import cx from 'classnames';
import styles from "../assets/styles/services.module.css?ver=0.1";
import ServicesDesktop from "../assets/images/service-section-img.png?ver=0.1";
import ServicesDesktopWebp from "../assets/images/service-section-img.webp?ver=0.1";
import ServicesMobile from "../assets/images/service-section-mob-img.png?ver=0.1";
import ServicesMobileWebp from "../assets/images/service-section-mob-img.webp?ver=0.1";
import SecuredappDesktop from "../assets/images/securedapp-section-img.png?ver=0.1";
import SecuredappDesktopWebp from "../assets/images/securedapp-section-img.webp?ver=0.1";
import SecuredappMobile from "../assets/images/securedapp-section-mob-img.png?ver0.1";
import SecuredappMobileWebp from "../assets/images/securedapp-section-mob-img.webp?ver=0.1";
import brokerImg from "../assets/images/broker-img.png";
import goldBroker from "../assets/images/gold-broker.png";
import premiumIcon from "../assets/images/premium-broker.png";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WebpImage from "./WebpImage"
import { formatResponseTime } from './../helpers/commons'

library.add(faStar)
const truncateMsg = (msg, size) => {
    return msg && msg.length > size ? msg.substr(0, size) + "..." : msg;
}

const PremiumImgTag = (type) => {
    if (type.type == '3') {
        return (<img
            src={goldBroker}
            alt="hashching-gold-broker"
            title="hashching-gold-broker"
        />)
    }
    else if (type.type == '1') {
        return (<img
            className={styles.premiumImg}
            src={premiumIcon}
            alt="hashching-premium-broker"
            title="hashching-premium-broker"
        />)

    }
    else {
        return null
    }
}

const ConvertDate = (reviewtime) => {
    var date = new Date(reviewtime.reviewtime);
    var options = { year: 'numeric', month: 'long', day: 'numeric' };

    return date.toLocaleDateString("en-US", options);
}
const Services = props => {
    const content = props.content;
    let allBrokerDetail = props.otherContent;

    //  allBrokerDetail.edges.sort(function(a, b){
    //     var aval =new Date(a.node.broker_details.latest_review_posted_on); 
    //     var bval =new Date(b.node.broker_details.latest_review_posted_on);
    //     return bval - aval
    // });
    //  allBrokerDetail.edges.sort(function(a, b){
    //     return b.node.premium - a.node.premium;
    // });
    if (!content) {
        return null;
    }

    return (
        <section className={styles.toolsSection}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={cx('col-xl-10 col-sm-12 mr-auto ml-auto', styles.titles)} >
                        <h3 className={styles.sectionTitle}>
                            {/* <span dangerouslySetInnerHTML={{ __html: content.label_headingprefix.value }}></span> */}
                            Personalised loan assessment.
                        </h3>
                        <h4 className={cx(styles.orange, styles.sectionsubTitle)}>without affecting your credit score!</h4>
                        <p>
                            HFG Marketplace provides a secure dashboard for you to simplify the process of your loan applications, and give you access to our products &amp; services. 
                            
                            {/* <span dangerouslySetInnerHTML={{ __html:content.label_headingpostfix.value}}></span>  */}
                        </p>
                    </div>
                    <div className={cx('col-xl-10 col-sm-12 mr-auto ml-auto', styles.toolsInnrSection, styles.ourserviceSection)}>
                        <div className={'row flex-column-reverse flex-xl-row flex-lg-row'}>
                            <div className={cx('col-xl-6 col-lg-6 col-md-12 col-sm-12', styles.toolsStepsMain)}>
                                <div className={styles.toolsSteps}>
                                    <div className={styles.toolsStepsInnr}>
                                        <div className={cx('d-flex', styles.stepsInfo)}>
                                            {/* <h4>   <span className={styles.stepDigit}>
                                                <span className={styles.digitTxt}>1</span></span></h4> */}
                                            <h3 className={styles.summrymain}> 
                                                <span className={styles.digitSumry}>
                                                    {/* {content.service_1[0].elements.label_mainheading1.value} */}
                                                    Get a free quote!
                                                </span>
                                            </h3>
                                        </div>
                                        <ul className={styles.stepsListng}>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    {/* <img src={icon1} alt="" />  */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
                                                        <path fill="#ff8a23" fillRule="nonzero" d="M15.926 7.632l1.44-1.256-1.22-1.4-1.443 1.258c.452.424.862.89 1.223 1.398zm-6.64-2.934V2.786h1.857V0H5.572v2.786h1.857v1.912C3.25 5.16 0 8.698 0 13a8.357 8.357 0 1 0 16.715 0c0-4.301-3.25-7.84-7.429-8.302zM8.358 19.5a6.5 6.5 0 1 1 0-13 6.5 6.5 0 1 1 0 13zm.928-11.144H7.43v5.572h4.643v-1.857H9.286V8.357z" />
                                                    </svg>
                                                </span>
                                                <span className={styles.stepListingTxt}>
                                                    {/* {content.service_1[0].elements.label_feature1.value} */}
                                                    Takes less than a minute.
                                                </span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    {/* <img src={icon2} alt="" /> */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                                        <g fill="#ff8a23" fillRule="evenodd">
                                                            <path fillRule="nonzero" d="M9.399 18.705C4.209 18.705 0 14.518 0 9.352 0 4.187 4.208 0 9.399 0c5.19 0 9.399 4.187 9.399 9.352 0 5.166-4.208 9.353-9.399 9.353zm-.01-1.747c4.248 0 7.69-3.426 7.69-7.653 0-4.226-3.442-7.652-7.69-7.652-4.247 0-7.69 3.426-7.69 7.652 0 4.227 3.443 7.653 7.69 7.653z" />
                                                            <path d="M10.312 8.672c-1.044-.367-1.466-.628-1.466-1.007 0-.331.256-.675 1.045-.675.63 0 1.102.141 1.43.273.084.034.17.031.252-.008a.29.29 0 0 0 .16-.19l.204-.75a.295.295 0 0 0-.185-.353 4.356 4.356 0 0 0-1.233-.271.294.294 0 0 1-.274-.297v-.49c0-.163-.15-.318-.318-.318h-.58c-.168 0-.294.155-.294.319v.615a.301.301 0 0 1-.235.288c-1.158.287-1.825 1.068-1.825 2.058 0 1.15.885 1.741 2.184 2.168.91.296 1.298.58 1.298 1.03 0 .462-.473.734-1.178.734-.633 0-1.219-.16-1.69-.364a.302.302 0 0 0-.256.004.29.29 0 0 0-.163.194l-.21.796a.293.293 0 0 0 .174.347c.406.174.95.322 1.54.378a.288.288 0 0 1 .262.295V14c0 .164.164.276.332.276h.592c.169 0 .268-.1.268-.264V13.321c0-.14.115-.258.254-.29 1.245-.283 1.923-1.14 1.923-2.133 0-1.078-.566-1.729-2.01-2.226" />
                                                        </g>
                                                    </svg>


                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_1[0].elements.label_feature2.value}</span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    {/* <img src={icon3} alt="" /> */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
                                                        <g fill="#ff8a23" fillRule="evenodd">
                                                            <path d="M9.357 17.139l-.873.354-.87-.307c-3.66-1.608-5.859-4.723-5.859-8.719V5.391l6.8-3.759 6.802 3.76v2.98c-.024 4.044-2.246 7.183-6 8.767zM16.828 4.95a.827.827 0 0 0-.473-.751L8.965.102a.898.898 0 0 0-.834 0L.633 4.2c-.293.136-.45.432-.45.751V8.59c0 4.552 2.68 8.19 6.825 10.01l1.168.433c.113.068.226.068.338.068a.896.896 0 0 0 .338-.068l1.211-.47c4.235-1.775 6.765-5.443 6.765-10.087V4.951z" />
                                                            <path d="M12.103 7.634l-4.01 4.47v-.001L5.586 9.31 6.59 8.193 8.093 9.87 11.1 6.517z" />
                                                        </g>
                                                    </svg>

                                                </span>
                                                <span className={styles.stepListingTxt}>
                                                    {/* {content.service_1[0].elements.label_feature3prefix.value} */}
                                                    We don’t sell your info. No spam.
                                                </span>
                                            </li>
                                        </ul>
                                        <div className={cx(styles.btnMain)}>
                                        <a className={cx('btn btn-primary', styles.btn)}  href="/loan-enquiry">Get Started</a>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className={cx('col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right', styles.imgPart)}>
                                <figure className={cx('figure', styles.figure)}>
                                    <WebpImage className={cx(styles.sectionimg, styles.dsktopimg)} src={ServicesDesktop} webp={ServicesDesktopWebp} alt="services" />
                                    <WebpImage className={cx(styles.sectionimg, styles.mobimg)} src={ServicesMobile} webp={ServicesMobileWebp} alt="services" />
                                    <figcaption className={cx('text-center', 'figure-caption', styles.figureCaption)}>{content.service_1[0].elements.label_bottomtextprefix.value}</figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.lightBackground}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={cx('col-xl-10 col-sm-12 mr-auto ml-auto', styles.toolsInnrSection, styles.ourbrokrSection)}>
                        <div className={'row'}>
                            <div className={cx('col-xl-7 col-lg-7 col-md-12', styles.ourbrokrCards)}>
                                <div className={styles.ourbrokrCardsContnt}>
                                    <div className={'d-flex'}>
                                        <div className={cx(styles.brokrDetail, styles.leftbrokr)}>
                                            <div className={styles.brokrDetailInnr}>
                                                <WebpImage className={styles.brokrImg} src={allBrokerDetail.edges[1].node.profile_pic} alt="broker-img" />

                                                <h4 className={styles.brokrNameStatus}>
                                                    <span className={styles.brokrName}>{allBrokerDetail.edges[1].node.fullName}</span>
                                                    <span className={styles.brokrstatus}>
                                                        <PremiumImgTag type={allBrokerDetail.edges[1].node.premium} />
                                                    </span>
                                                </h4>
                                                <h5 className={styles.brokrLocation}>{truncateMsg(allBrokerDetail.edges[1].node.broker_details.aggregatorBusinessName, 30)}</h5>
                                                {(allBrokerDetail.edges[1].node.review_count > '3') && (<h4 className={styles.totalReviews}>
                                                    <span className={styles.reviewRating}>
                                                        <span className={cx('badge', styles.badge)}>
                                                            <i className={cx(styles.fa, styles.faStar)}>
                                                                <FontAwesomeIcon icon={faStar} />
                                                            </i>
                                                            <span className={styles.ratingTxt}>{allBrokerDetail.edges[1].node.broker_rating}</span>
                                                        </span>
                                                    </span>
                                                    <span className={styles.countReviewsMain}><span className={styles.countReviws}>{allBrokerDetail.edges[1].node.review_count}</span>
                                                        <span className={styles.reviwsTxt}>{(allBrokerDetail.edges[1].node.review_count > 1) ? 'Reviews' : 'Review'}</span></span>
                                                </h4>)}
                                                {(allBrokerDetail.edges[1].node.review_count <= '3') && (<h4 className={styles.totalReviews}>
                                                    <span className={styles.countReviewsMain}>
                                                        <span className={styles.countReviws}>{allBrokerDetail.edges[1].node.deals_count}</span>
                                                        <span className={styles.reviwsTxt}>
                                                            {'Active Deals'}
                                                        </span>
                                                    </span>
                                                    <span className={styles.countReviewsMain}>
                                                        <span className={styles.countReviws}>{formatResponseTime(allBrokerDetail.edges[1].node.broker_details.avgResponse)}</span>
                                                        <span className={styles.reviwsTxt}>
                                                            {'Response Time'}
                                                        </span>
                                                    </span>
                                                </h4>)}
                                                <p className={styles.brokrSumry}>“{truncateMsg(allBrokerDetail.edges[1].node.broker_details.latest_review, 30)}”</p>
                                                <p className={styles.brokrTimeStatus}><ConvertDate reviewtime={allBrokerDetail.edges[1].node.broker_details.latest_review_posted_on} /> by {truncateMsg(allBrokerDetail.edges[1].node.broker_details.latest_review_by, 6)}</p>
                                            </div>
                                        </div>
                                        <div className={cx(styles.brokrDetail, styles.middlebrokr)}>
                                            <div className={styles.brokrDetailInnr}>
                                                <WebpImage className={styles.brokrImg} src={allBrokerDetail.edges[0].node.profile_pic} alt="broker-img" />
                                                <h4 className={styles.brokrNameStatus}>
                                                    <span className={styles.brokrName}>{allBrokerDetail.edges[0].node.fullName}</span>
                                                    <span className={styles.brokrstatus}>
                                                        <PremiumImgTag type={allBrokerDetail.edges[0].node.premium} />
                                                    </span>
                                                </h4>
                                                <h5 className={styles.brokrLocation}>{truncateMsg(allBrokerDetail.edges[0].node.broker_details.aggregatorBusinessName, 30)}</h5>
                                                {(allBrokerDetail.edges[1].node.review_count > '3') && (<h4 className={styles.totalReviews}>
                                                    <span className={styles.reviewRating}>
                                                        <span className={cx('badge', styles.badge)}>
                                                            <i className={cx(styles.fa, styles.faStar)}>
                                                                <FontAwesomeIcon icon={faStar} />
                                                            </i>
                                                            <span className={styles.ratingTxt}>{allBrokerDetail.edges[0].node.broker_rating}</span>
                                                        </span>
                                                    </span>
                                                    <span className={styles.countReviewsMain}><span className={styles.countReviws}>{allBrokerDetail.edges[0].node.review_count}</span>
                                                        <span className={styles.reviwsTxt}>{(allBrokerDetail.edges[0].node.review_count > 1) ? 'Reviews' : 'Review'}</span></span>
                                                </h4>)}
                                                {(allBrokerDetail.edges[0].node.review_count <= '3') && (<h4 className={styles.totalReviews}>
                                                    <span className={styles.countReviewsMain}>
                                                        <span className={styles.countReviws}>{allBrokerDetail.edges[0].node.deals_count}</span>
                                                        <span className={styles.reviwsTxt}>
                                                            {'Active Deals'}
                                                        </span>
                                                    </span>
                                                    <span className={styles.countReviewsMain}>
                                                        <span className={styles.countReviws}>{formatResponseTime(allBrokerDetail.edges[0].node.broker_details.avgResponse)}</span>
                                                        <span className={styles.reviwsTxt}>
                                                            {'Response Time'}
                                                        </span>
                                                    </span>
                                                </h4>)}
                                                <p className={styles.brokrSumry}>“{truncateMsg(allBrokerDetail.edges[0].node.broker_details.latest_review, 30)}”</p>
                                                <p className={styles.brokrTimeStatus}><ConvertDate reviewtime={allBrokerDetail.edges[0].node.broker_details.latest_review_posted_on} /> by {truncateMsg(allBrokerDetail.edges[0].node.broker_details.latest_review_by, 6)}</p>
                                            </div>
                                        </div>

                                        <div className={cx(styles.brokrDetail, styles.rightbrokr)}>
                                            <div className={styles.brokrDetailInnr}>
                                                <WebpImage className={styles.brokrImg} src={allBrokerDetail.edges[2].node.profile_pic} alt="broker-img" />
                                                <h4 className={styles.brokrNameStatus}>
                                                    <span className={styles.brokrName}>{allBrokerDetail.edges[2].node.fullName}</span>
                                                    <span className={styles.brokrstatus}>
                                                        <PremiumImgTag type={allBrokerDetail.edges[2].node.premium} />
                                                    </span>
                                                </h4>
                                                <h5 className={styles.brokrLocation}>{truncateMsg(allBrokerDetail.edges[2].node.broker_details.aggregatorBusinessName, 30)}</h5>
                                                {(allBrokerDetail.edges[2].node.review_count > '3') && (<h4 className={styles.totalReviews}>
                                                    <span className={styles.reviewRating}>
                                                        <span className={cx('badge', styles.badge)}>
                                                            <i className={cx(styles.fa, styles.faStar)}>
                                                                <FontAwesomeIcon icon={faStar} />
                                                            </i>
                                                            <span className={styles.ratingTxt}>{allBrokerDetail.edges[2].node.broker_rating}</span>
                                                        </span>
                                                    </span>
                                                    <span className={styles.countReviewsMain}><span className={styles.countReviws}>{allBrokerDetail.edges[2].node.review_count}</span>
                                                        <span className={styles.reviwsTxt}>{(allBrokerDetail.edges[2].node.review_count > 1) ? 'Reviews' : 'Review'}</span></span>
                                                </h4>)}
                                                {(allBrokerDetail.edges[2].node.review_count <= '3') && (<h4 className={styles.totalReviews}>
                                                    <span className={styles.countReviewsMain}>
                                                        <span className={styles.countReviws}>{allBrokerDetail.edges[2].node.deals_count}</span>
                                                        <span className={styles.reviwsTxt}>
                                                            {'Active Deals'}
                                                        </span>
                                                    </span>
                                                    <span className={styles.countReviewsMain}>
                                                        <span className={styles.countReviws}>{formatResponseTime(allBrokerDetail.edges[2].node.broker_details.avgResponse)}</span>
                                                        <span className={styles.reviwsTxt}>
                                                            {'Response Time'}
                                                        </span>
                                                    </span>
                                                </h4>)}
                                                <p className={styles.brokrSumry}>“{truncateMsg(allBrokerDetail.edges[2].node.broker_details.latest_review, 30)}”</p>
                                                <p className={styles.brokrTimeStatus}><ConvertDate reviewtime={allBrokerDetail.edges[2].node.broker_details.latest_review_posted_on} /> by {truncateMsg(allBrokerDetail.edges[2].node.broker_details.latest_review_by, 6)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <figure className={cx('figure', styles.figure)}>
                                    <figcaption className={cx('text-center', 'figure-caption', styles.figureCaption)}>
                                        {/* {content.service_2[0].elements.label_bottomtextprefix.value} <br /> {content.service_2[0].elements.label_bottomtextpostfix.value}  */}
                                        Our verified brokers have an average of 10+ years of experience in home loans and finance
                                    </figcaption>
                                </figure>
                            </div>
                            <div className={cx('col-xl-5 col-lg-5 col-md-12', styles.toolsStepsMain)}>
                                <div className={styles.toolsSteps}>
                                    <div className={styles.toolsStepsInnr}>
                                        <div className={cx('d-flex', styles.stepsInfo)}>
                                            {/* <h4>   <span className={styles.stepDigit}>
                                                <span className={styles.digitTxt}>2</span></span></h4> */}
                                            <h3>  <span className={styles.digitSumry}>{content.service_2[0].elements.label_mainheading1.value} <br /> {content.service_2[0].elements.label_mainheading2.value}<br />{content.service_2[0].elements.label_mainheading3.value}</span>
                                            </h3>
                                        </div>
                                        <ul className={styles.stepsListng}>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                        <g fill="#ff8a23" fillRule="evenodd">
                                                            <path fillRule="nonzero" d="M8 14.33A6.33 6.33 0 1 0 8 1.67a6.33 6.33 0 0 0 0 12.66zM8 16c-4.42 0-8-3.581-8-8a8 8 0 1 1 8 8z" />
                                                            <path d="M7.01 9H7V4h1.872v3.148H12V9H7.01z" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_2[0].elements.label_feature1.value}</span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                        <path fill="#ff8a23" fillRule="evenodd" d="M4 9h2V7H4v2zm0 3h2v-2H4v2zm3-3h2V7H7v2zm0 3h2v-2H7v2zm3-3h2V7h-2v2zm0 3h2v-2h-2v2zm4-6a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6zm0 10H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2v1a1 1 0 0 0 2 0V0h4v1a1 1 0 0 0 2 0V0h2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2z" />
                                                    </svg>
                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_2[0].elements.label_feature2.value}</span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
                                                        <path fill="#FFF" fillRule="evenodd" stroke="#ff8a23" strokeWidth="1.5" d="M18.068 7.35l-4.863-.656-2.865-5.113a.421.421 0 0 0-.678 0L6.796 6.694l-4.863.655a.407.407 0 0 0-.27.275.43.43 0 0 0 .061.383l3.912 4.128-.96 5.206a.427.427 0 0 0 .167.353.42.42 0 0 0 .241.082.396.396 0 0 0 .138-.025L10 15.186l4.776 2.561a.408.408 0 0 0 .547-.407l-.96-5.207 3.912-4.128a.429.429 0 0 0 .064-.38.414.414 0 0 0-.271-.276z" />
                                                    </svg>

                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_2[0].elements.label_feature3prefix.value}</span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                                        <path fill="#ff8a23" fillRule="nonzero" stroke="#ff8a23" strokeWidth=".5" d="M16.75 13.938A8.46 8.46 0 0 0 18 9.5a8.46 8.46 0 0 0-1.25-4.438l-.005-.007a8.5 8.5 0 0 0-14.49 0l-.005.007a8.503 8.503 0 0 0 0 8.876l.005.007a8.5 8.5 0 0 0 14.49 0l.005-.007zm-6.164 2.605a2.066 2.066 0 0 1-.551.381c-.339.161-.731.161-1.07 0a2.602 2.602 0 0 1-.964-.848 7.597 7.597 0 0 1-1.098-2.382 41.513 41.513 0 0 1 5.194 0c-.125.438-.28.866-.466 1.282a4.929 4.929 0 0 1-1.045 1.567zM1.972 9.978H5.4c.022.956.126 1.91.31 2.848a43.11 43.11 0 0 0-2.804.34 7.493 7.493 0 0 1-.934-3.188zm.934-4.144c.932.144 1.867.258 2.805.34a16.838 16.838 0 0 0-.31 2.848H1.97a7.493 7.493 0 0 1 .935-3.188zm5.507-3.377c.162-.157.349-.285.552-.381.339-.161.731-.161 1.07 0 .387.197.718.49.964.848.501.724.873 1.53 1.098 2.382a41.526 41.526 0 0 1-5.194 0c.125-.438.28-.866.466-1.282a4.929 4.929 0 0 1 1.044-1.567zm8.615 6.565H13.6a16.869 16.869 0 0 0-.31-2.848 43.105 43.105 0 0 0 2.804-.34c.546.98.866 2.07.934 3.188zm-10.357 3.73a15.796 15.796 0 0 1-.316-2.774h6.29c-.023.932-.128 1.86-.315 2.774a42.359 42.359 0 0 0-5.66 0zm5.658-6.504c.187.914.293 1.842.316 2.774h-6.29c.023-.932.128-1.86.315-2.774a42.374 42.374 0 0 0 5.66 0zm1.27 3.73h3.43a7.493 7.493 0 0 1-.935 3.188 42.538 42.538 0 0 0-2.805-.34c.184-.939.288-1.892.31-2.848zm1.923-5.023a41.26 41.26 0 0 1-2.452.28A10.891 10.891 0 0 0 12.5 3.63a6.777 6.777 0 0 0-.79-1.345 7.543 7.543 0 0 1 3.81 2.67zm-11.357-.79a7.542 7.542 0 0 1 3.124-1.88l-.054.069a8.718 8.718 0 0 0-1.303 2.88 42.79 42.79 0 0 1-2.454-.28c.21-.278.44-.542.687-.79zm-.687 9.88a41.24 41.24 0 0 1 2.452-.28c.148.55.338 1.086.569 1.605.21.477.476.929.79 1.345a7.543 7.543 0 0 1-3.81-2.67zm11.357.79a7.542 7.542 0 0 1-3.124 1.88l.054-.069a8.718 8.718 0 0 0 1.303-2.88c.82.07 1.638.162 2.454.28-.21.278-.44.542-.687.79z" />
                                                    </svg>
                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_2[0].elements.label_feature4prefix.value}</span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                                                        <path fill="none" fillRule="evenodd" stroke="#ff8a23" strokeWidth="1.5" d="M1 5.875C1 3.185 3.46 1 6.5 1S12 3.184 12 5.875C12 8.575 6.5 16 6.5 16S1 8.575 1 5.875zM6.5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                                    </svg>
                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_2[0].elements.label_feature5.value}</span>
                                            </li>
                                        </ul>
                                        <div className={cx(styles.btnMain)}>
                                        <a className={cx('btn btn-primary', styles.btn)}  href="/mortgage-broker-finder">Find a Broker</a>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={cx('col-xl-10 col-sm-12 mr-auto ml-auto', styles.toolsInnrSection, styles.oursecuredappSection)}>
                        <div className={'row flex-column-reverse flex-xl-row flex-lg-row'}>
                            <div className={cx('col-xl-6 col-lg-6 col-md-12 col-sm-12', styles.toolsStepsMain)}>
                                <div className={styles.toolsSteps}>
                                    <div className={styles.toolsStepsInnr}>
                                        <div className={cx('d-flex', styles.stepsInfo)}>
                                            {/* <h4> <span className={styles.stepDigit}>  <span className={styles.digitTxt}>3</span></span>
                                            </h4>  */}
                                            <h3>  <span className={styles.digitSumry}>
                                                {/* {content.service_3[0].elements.label_mainheading1.value}<br />{content.service_3[0].elements.label_mainheading2.value} */}
                                                Manage &amp; track your application via our secure portal
                                                </span>

                                            </h3>
                                        </div>
                                        <ul className={styles.stepsListng}>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                                                            <circle cx="10" cy="10" r="10" fill="#FFF" stroke="#ff8a23" strokeWidth="2" />
                                                            <path fill="#ff8a23" d="M9.054 13.593L5.4 10.087l1.327-1.272 2.327 2.213L13.669 6.6l1.346 1.274z" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_3[0].elements.label_feature1.value}</span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21">
                                                        <g fill="#ff8a23" fillRule="evenodd">
                                                            <path d="M15.893 18.304H4.016c-1.104 0-2.008-.915-2.008-2.033V2.034h11.877c1.104 0 2.008.915 2.008 2.034l-1.375 2.095-1.579 3.703 2.954 5.117v3.321zM13.885 0H0v16.27c0 2.238 1.807 4.068 4.016 4.068h13.885V15.88l-4.016-6.133 4.016-4.53v-1.15C17.9 1.83 16.094 0 13.885 0z" />
                                                            <path d="M4.77 12.203h8.098a1.017 1.017 0 1 0 0-2.034H4.77a1.017 1.017 0 1 0 0 2.034zM4.77 8.048h3.164a1.017 1.017 0 1 0 0-2.034H4.77a1.017 1.017 0 1 0 0 2.034z" />
                                                            <path d="M18.092 3.249c3.607 0 6.533 2.962 6.533 6.617 0 3.653-2.926 6.616-6.533 6.616-3.609 0-6.534-2.963-6.534-6.616 0-3.655 2.925-6.617 6.534-6.617zm3.818 5.04L20.77 7.092l-3.416 3.597-1.708-1.799-1.138 1.2 2.846 2.998h.001L21.91 8.29z" />
                                                        </g>
                                                    </svg>

                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_3[0].elements.label_feature2.value}</span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
                                                        <g fill="#ff8a23" fillRule="evenodd" transform="matrix(-1 0 0 1 20 0)">
                                                            <path d="M2.07 2.228c0-.134.09-.223.224-.223h14.962c.134 0 .224.089.224.223v10.67c0 .133-.09.223-.224.223h-4.78c-.29 0-.702.2-.791.492l-.55 2.316-2.424-2.607c-.135-.134-.505-.201-.707-.201h-5.71c-.134 0-.223-.09-.223-.224V2.228zm8.672 15.92c.189.236.45.354.733.354.331 0 .615-.165.805-.45.047-.046.07-.118.094-.189l.828-2.957h4.764c.922 0 1.656-.733 1.656-1.656V1.656C19.622.734 18.888 0 17.966 0H1.656C.733 0 0 .734 0 1.656V13.25c0 .923.733 1.656 1.656 1.656H7.62l3.123 3.241z" />
                                                            <circle cx="5.213" cy="7.662" r="1.49" />
                                                            <circle cx="9.682" cy="7.662" r="1.49" />
                                                            <circle cx="14.15" cy="7.662" r="1.49" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_3[0].elements.label_feature3prefix.value}<br />{content.service_3[0].elements.label_feature3postfix.value}</span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                        <g fill="none" fillRule="evenodd">
                                                            <path stroke="#ff8a23" strokeWidth="2" d="M4.895 8.792c-.009-.162.04-.33.14-.526.88-1.72 2.275-2.964 3.803-4.059.688-.493 1.496-.823 2.263-1.2a.668.668 0 0 1 .467-.069 1.449 1.449 0 0 1 2.529-1.067l3.715 4.191a1.449 1.449 0 0 1-1.453 2.362c.009.152-.04.308-.153.48-.472.724-.95 1.446-1.438 2.188.06.068.148.171.24.27 2.976 3.201 5.948 6.406 8.931 9.598.577.618 1.033 1.27.994 2.16l-.067.225c-.059.076-.124.147-.176.228-.797 1.249-2.118 1.394-3.146.343a7.068 7.068 0 0 1-.39-.436c-1.988-2.408-3.973-4.819-5.961-7.227-.903-1.093-1.81-2.183-2.651-3.198-.845.404-1.61.783-2.388 1.137a.735.735 0 0 1-.483.067 1.447 1.447 0 0 1-2.528 1.081l-3.715-4.19a1.448 1.448 0 0 1 1.467-2.358zM3.208 21.538c-.098-.116-.163-.275-.196-.482-.053-.32-.048-.64.094-.944a.689.689 0 0 1 .632-.426c.104-.005.21-.002.314-.002h6.962c.59 0 .886.262.928.85.014.207.004.422-.039.624a.884.884 0 0 1-.166.377h.59c1.12 0 1.704.697 1.522 1.812-.093.565-.34 1.008-.938 1.157a1.89 1.89 0 0 1-.45.038c-1.665.002-3.33.001-4.997.001h-4.86c-1.106 0-1.688-.672-1.507-1.776.042-.258.14-.523.277-.745.229-.374.613-.485 1.037-.485h.797z" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span className={styles.stepListingTxt}>
                                                    {/* {content.service_3[0].elements.label_feature4prefix.value}<br />{content.service_3[0].elements.label_feature4postfix.value} */}
                                                    We connect you to an experienced solicitor for your<br />
                                                    conveyancing needs at a negotiated price.
                                                </span>
                                            </li>
                                            <li>
                                                <span className={styles.stepListingIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
                                                        <g fill="#ff8a23" fillRule="evenodd">
                                                            <path d="M10.988 7.415c.73 0 1.336.582 1.37 1.302v.845h-2.74v-.845c.034-.72.64-1.302 1.37-1.302m-.605 4.545a.63.63 0 0 1 .537-.514.622.622 0 0 1 .696.617.59.59 0 0 1-.297.525v.81a.32.32 0 1 1-.64 0v-.81a.583.583 0 0 1-.296-.628m2.935-2.398v-.777c0-1.279-1.04-2.329-2.33-2.329a2.332 2.332 0 0 0-2.33 2.33v.776a1.163 1.163 0 0 0-1.141 1.153v3.677c0 .64.514 1.153 1.153 1.153h4.625a1.15 1.15 0 0 0 1.153-1.153v-3.677c0-.628-.502-1.142-1.13-1.153" />
                                                            <path d="M11.724 21.271l-1.07.438-.839-.38C5.331 19.348 2.41 15.507 2.41 10.58V6.636L10.74 2l8.563 4.635v3.828c-.029 4.985-2.98 8.855-7.58 10.808zm9.47-15.148c0-.394-.223-.76-.585-.93L11.28.128a1.111 1.111 0 0 0-1.031 0L1.167 5.194c-.362.169-.557.535-.557.929v4.5c0 5.63 3.315 10.127 8.44 12.38l1.253.534c.14.085.279.085.418.085.14 0 .279-.029.418-.085l1.42-.62c5.237-2.195 8.635-6.693 8.635-12.435v-4.36z" />
                                                        </g>
                                                    </svg>

                                                </span>
                                                <span className={styles.stepListingTxt}>{content.service_3[0].elements.label_feature5.value}</span>
                                            </li>
                                        </ul>
                                        <div className={cx(styles.btnMain)}>
                                        <a className={cx('btn btn-primary', styles.btn)} href="/consumer-login">Register for FREE</a>
                                        <p className={cx(styles.infotxt)}>Your own personalised HFG Marketplace dashboard to save thousands!</p>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right', styles.imgPart)}>
                                <figure className={cx('figure', styles.figure)}>
                                    <WebpImage className={cx(styles.sectionimg, styles.dsktopimg)} src={SecuredappDesktop} webp={SecuredappDesktopWebp} alt="securedapp" />
                                    <WebpImage className={cx(styles.sectionimg, styles.mobimg)} src={SecuredappMobile} webp={SecuredappMobileWebp} alt="securedapp" />
                                    <figcaption className={cx('text-center', 'figure-caption', styles.figureCaption)}>{content.service_3[0].elements.label_bottomtextprefix.value} <br /> {content.service_3[0].elements.label_bottomtextpostfix.value} </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={"section text-center"}>
                <a class="btn btn-primary" href="/loan-enquiry">Apply for Home Loan</a>
            </div> */}
        </section>
    );
};

export default Services;