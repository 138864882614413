

import React, { Component } from "react";
import cx from 'classnames';
import Styles from '../assets/styles/howItWorksSteps.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import newslogos from "../assets/images/newslogos.svg";

import "../assets/styles/stats-section.css?ver=0.1";
import "../assets/styles/custom-button.css?ver=0.1";


library.add(faCheck);

class HashchingStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // circularAnimation: '',
            // allContents: '', 
        }
    }

    render() {
        return (
            <section className={"aboutUsSection"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-lg-12 col-md-12 aboutUsIcons text-center"}>
                            <h5 className={cx("strong", Styles.sectionTitle)}>Our experienced brokers will search the entire market to find you the best possible deal</h5>
                            <div className={"aboutUsIconsinner"}>
                                <div className={"row"}>
                                    <div className={"col-lg-3 col-sm-6 col-12 singleIconPart"}>
                                        <figure>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="86.196" height="100.058" viewBox="0 0 86.196 100.058">
                                                <path id="Path_450" data-name="Path 450" d="M94.443,58.02a1.764,1.764,0,0,0-.353-1.886L79.635,39.58l5.853-15.143a1.763,1.763,0,0,0-1.639-2.4H66.555L62.624,7.478a1.763,1.763,0,0,0-1.763-1.3h-37.5a5.29,5.29,0,0,0-5.959-2.539L12.293,5.01a5.289,5.289,0,0,0-3.737,6.452l9.2,34.323a5.289,5.289,0,0,0,2.715,3.332L33.994,99.606a5.3,5.3,0,0,0,6.47,3.737l1.763-.458a5.288,5.288,0,0,0,3.737-6.47L32.39,45.944l.159-.229a5.274,5.274,0,0,0,.688-2.521H48.45l3.914,14.561a1.763,1.763,0,0,0,1.763,1.3H92.91A1.762,1.762,0,0,0,94.443,58.02Zm-26.8-18.352H32.531L24.492,9.7H59.574ZM11.958,10.581A1.763,1.763,0,0,1,13.21,8.43l5.077-1.375A1.764,1.764,0,0,1,20.4,8.218a1.472,1.472,0,0,0,0,.176L29.393,41.89a1.471,1.471,0,0,0,0,.176l.159.564A1.763,1.763,0,0,1,28.3,44.781l-5.112,1.375a1.763,1.763,0,0,1-2.027-1.2ZM41.2,99.606l-1.763.458a1.763,1.763,0,0,1-2.151-1.252L24.1,49.629h0l5.112-1.375h0L42.4,97.438A1.764,1.764,0,0,1,41.2,99.606ZM52.029,43.194h13.31l-10.3,11.23Zm6.8,12.34,11.388-12.34A1.689,1.689,0,0,0,71.6,41.043L67.489,25.565H81.275L75.986,39.3a1.767,1.767,0,0,0,.317,1.763l12.64,14.473Z" transform="translate(-8.38 -3.462)" fill="#ff8a23"></path>
                                            </svg>
                                            <h5>2015</h5>
                                            <p>The Year <br />Founded</p>
                                        </figure>
                                    </div>
                                    <div className={"col-lg-3 col-sm-6 col-12 singleIconPart"}>
                                        <figure>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100.017" height="100.024" viewBox="0 0 100.017 100.024">
                                                <g id="Group_427" data-name="Group 427" transform="translate(-4 -3.996)">
                                                    <path id="Path_447" data-name="Path 447" d="M28.658,25.86V24.378a4.965,4.965,0,0,0-2.465-9.234h0A1.411,1.411,0,1,1,27.6,13.733h3.572a4.982,4.982,0,0,0-3.8-4.84V8H23.8V9.429a4.965,4.965,0,0,0,2.393,9.287h0a1.411,1.411,0,1,1-1.411,1.411H21.21a4.983,4.983,0,0,0,3.929,4.858v.875Z" transform="translate(13.527 3.147)" fill="#ff8a23"></path>
                                                    <path id="Path_448" data-name="Path 448" d="M98.659,43.2a14.415,14.415,0,0,0-14.4-14.4H77.388l-8.126-7.376A4.84,4.84,0,0,0,61.153,25V32.02l-.411.357H54.009v3.572H27.218V32.377H18.628A14.646,14.646,0,0,0,4,47V67.758A14.521,14.521,0,0,0,16.5,82.225v9.091H32.576v-8.93H62.939v8.93H79.013v-8.93h5.251A14.43,14.43,0,0,0,98.177,71.67h5.84V57.382H98.659Zm1.786,17.753V68.1H98.659v-.107H95.087A10.841,10.841,0,0,1,84.264,78.814H75.441v8.93h-8.93v-8.93H29v8.93h-8.93v-8.93H18.288A10.985,10.985,0,0,1,7.572,67.776V47A11.055,11.055,0,0,1,18.628,35.949h5.019v3.572H57.581V35.949h4.572l.518-.5a10.688,10.688,0,0,1,1.286-1.089l.75-.536V25.019a1.268,1.268,0,0,1,2.143-.947l9.144,8.3h8.251A10.843,10.843,0,0,1,95.087,43.2V60.954Z" transform="translate(0 12.704)" fill="#ff8a23"></path>
                                                    <path id="Path_449" data-name="Path 449" d="M47.148,20.077a16.074,16.074,0,1,0-17.86,15.967v4.715l-4.09-4.09L22.68,39.188,31.074,47.6l8.412-8.412-2.518-2.518L32.86,40.777V36.044A16.073,16.073,0,0,0,47.148,20.077Zm-28.576,0a12.5,12.5,0,1,1,12.5,12.5A12.5,12.5,0,0,1,18.572,20.077Z" transform="translate(8.646 0)" fill="#ff8a23"></path>
                                                    <circle id="Ellipse_51" data-name="Ellipse 51" cx="3.572" cy="3.572" r="3.572" transform="translate(80.799 52.226)" fill="#ff8a23"></circle>
                                                </g>
                                            </svg>
                                            <h5>
                                                70,000+
                                            </h5>
                                            <p>Borrowers <br />Helped</p>
                                        </figure>
                                    </div>
                                    <div className={"col-lg-3 col-sm-6 col-12 singleIconPart"}>
                                        <figure>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="86.877" height="100.487" viewBox="0 0 86.877 100.487">
                                                <g id="Group_428" data-name="Group 428" transform="translate(-8 -4.48)">
                                                    <path id="Path_451" data-name="Path 451" d="M64.519,30.29A25.81,25.81,0,1,0,38.71,56.1h0A25.846,25.846,0,0,0,64.519,30.29Zm-48.018,0A22.19,22.19,0,1,1,38.71,52.479,22.19,22.19,0,0,1,16.5,30.29Z" transform="translate(3.969)" fill="#ff8a23"></path>
                                                    <path id="Path_452" data-name="Path 452" d="M8,66.009v18.1a1.811,1.811,0,0,0,1.81,1.81H74.968a1.811,1.811,0,0,0,1.81-1.81V77.031l15.547,6.914a1.81,1.81,0,0,0,2.393-.909,1.788,1.788,0,0,0,.159-.738V46.1a18.1,18.1,0,1,0-36.2,0H27.909A19.91,19.91,0,0,0,8,66.009ZM73.158,82.3H64.941l8.217-3.62Zm9.05-6.805L77.52,73.411a1.813,1.813,0,0,0-1.466,0l-4.706,2.081V63.366a18.1,18.1,0,0,0,10.86,0Zm9.05,4.018-5.43-2.425a1.451,1.451,0,0,0,0-.217V61.755a18.206,18.206,0,0,0,5.43-4.8ZM76.777,31.62A14.479,14.479,0,1,1,62.3,46.1,14.48,14.48,0,0,1,76.777,31.62ZM62.3,56.959a18.188,18.188,0,0,0,5.43,4.833V76.868a1.451,1.451,0,0,0,0,.217L62.3,79.511Zm-34.389-7.24H56.868a16.8,16.8,0,0,1,1.81.109V82.3H11.62V66.009A16.288,16.288,0,0,1,27.909,49.719Z" transform="translate(0 19.05)" fill="#ff8a23"></path>
                                                </g>
                                            </svg>
                                            <h5>2000+</h5>
                                            <p>Certified <br />Brokers</p>
                                        </figure>
                                    </div>
                                    <div className={"col-lg-3 col-sm-6 col-12 singleIconPart"}>
                                        <figure>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="102.836" height="101" viewBox="0 0 102.836 101">
                                                <g id="noun_House_1884342" transform="translate(-4 -4)">
                                                    <path id="Path_599" data-name="Path 599" d="M92.145,30.756V5.836A1.836,1.836,0,0,0,90.309,4H71.945a1.836,1.836,0,0,0-1.836,1.836v8.594L56.52,4.367a1.836,1.836,0,0,0-2.185,0L4.753,41.095A1.836,1.836,0,0,0,4,42.564V57.255a1.836,1.836,0,0,0,2.938,1.469l4.407-3.305v47.745A1.836,1.836,0,0,0,13.182,105H97.655a1.836,1.836,0,0,0,1.836-1.836V55.418l4.407,3.269a1.836,1.836,0,0,0,2.938-1.432V42.564a1.836,1.836,0,0,0-.735-1.469ZM73.782,7.673H88.473v20.2L73.782,16.855ZM26.036,101.327V66.436H48.073v34.891Zm69.782,0H51.745V64.6a1.836,1.836,0,0,0-1.836-1.836H24.2A1.836,1.836,0,0,0,22.364,64.6v36.727H15.018V52.737L55.418,22.8l40.4,29.933Zm7.345-47.745L98.9,50.423a.208.208,0,0,0-.147-.184l-41.979-31-.275-.2a1.836,1.836,0,0,0-2.185,0l-.275.2L12.1,50.276a2.014,2.014,0,0,1-.147.184L7.673,53.582v-10.1L55.418,8.113l47.745,35.368Z" fill="#ff8a23"></path>
                                                    <path id="Path_600" data-name="Path 600" d="M35.836,62.545H59.709a1.836,1.836,0,0,0,1.836-1.836V36.836A1.836,1.836,0,0,0,59.709,35H35.836A1.836,1.836,0,0,0,34,36.836V60.709A1.836,1.836,0,0,0,35.836,62.545Zm1.836-11.018h8.576v7.345H37.673Zm12.249,7.345V51.527h7.951v7.345Zm7.951-11.018H49.921V38.673h7.951ZM46.249,38.673v9.182H37.673V38.673Z" transform="translate(25.091 25.927)" fill="#ff8a23"></path>
                                                </g>
                                            </svg>
                                            <h5>$2B+</h5>
                                            <p>Settled <br />In Loans</p>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            {/* <a href="/loan-enquiry" className={"btn-primary-3D getStartedBtn"}> 
                          <span>Get Started</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="17.566" height="18.845" viewBox="0 0 17.566 18.845"><g id="Group_10" data-name="Group 10" transform="translate(1.5 2.121)"><line id="Line_1" data-name="Line 1" x2="13.773" transform="translate(0 7.292)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"></line><path id="Path_501" data-name="Path 501" d="M802,392.524l7.3,7.3-7.3,7.3" transform="translate(-794.736 -392.524)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path></g>
                          </svg>
                          </a> */}
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};

export default HashchingStats;
