

import React, { Component } from "react";
import cx from 'classnames';
import Styles from '../assets/styles/howItWorksSteps.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import newslogos from "../assets/images/newslogos.svg";
import "../assets/styles/news-section.css?ver=0.1";
import "../assets/styles/custom-button.css?ver=0.1";
library.add(faCheck);

class HashchingInNews extends Component {

    constructor(props) {
      super(props);
      this.state = {
        // circularAnimation: '',
        // allContents: '', 
      }
    }
 
    render() { 
        return (
        <section className={"sectionPadding relative-section inner-sections news-section"}>
            <div className={"container"}>
                <div className={"row flex-column-reverse flex-xl-row flex-lg-row"}>
                <div className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"}>
                <h6 className={""}>
                    <strong>Featured in:</strong>
                </h6>
                    
                    <img className={"newsLogoImg"} src={newslogos} alt="logoimage" />
                
                    <a target="_blank" href="/about/hashching-in-the-news" className={"btn-primary-3D"}>
                    HFG Marketplace in the news
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.566" height="18.845" viewBox="0 0 17.566 18.845">
                        <g id="Group_10" data-name="Group 10" transform="translate(1.5 2.121)">
                        <line id="Line_1" data-name="Line 1" x2="13.773" transform="translate(0 7.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3" />
                        <path id="Path_501" data-name="Path 501" d="M802,392.524l7.3,7.3-7.3,7.3" transform="translate(-794.736 -392.524)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                        </g>
                    </svg>
                    </a>
                </div>

                <div className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"}>
                    <div className={"videoresponse video-responsive"}>
                    <iframe
                        width="853"
                        height="480"
                        src={`https://www.youtube.com/embed/fjq-h955Cfs`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                        
                    </div>
                </div>
                </div>
            </div>
            </section>
        );
  };
};

export default HashchingInNews;
