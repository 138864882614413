import React from 'react';
import cx from 'classnames';
import styles from "../assets/styles/homeBottomGetStarted.module.css";
import Cookies from 'universal-cookie';
import { navigate } from 'gatsby';

const homeGetStartedClick = (event, fromPage ='') => {
    event.preventDefault()
    let cookies = new Cookies();
    cookies.remove('deal', { path: '/' })
    cookies.remove('broker', { path: '/' })
    cookies.remove('funnelData', { path: '/' })
    cookies.remove('SaveEmail', { path: '/' })
    cookies.remove('SaveName', { path: '/' })
    if (typeof (Storage) !== "undefined") {
        sessionStorage.removeItem("landingData");
    }
    navigate('/loan-enquiry')
}


const HomeBottomGetStarted = props => {
  const content = props.content;
  if(!content) {
    return null;
  }
  
  return (
       <section className={cx(styles.readySection, 'text-center')}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={cx('col-12', styles.readySectionContnt)}>
                        <h2 className={styles.sectionHeading}> <span dangerouslySetInnerHTML={{ __html: content.label_text1.value }}></span></h2>
                        <div className={styles.getStarted}>
                          <a className={cx(styles.getStartedLink, styles.lightblueBtn)} href={content.label_button[0].elements.href.value} onClick={
                              (event) => {
                                  homeGetStartedClick(event);
                              }
                          }>{content.label_button[0].elements.label.value}</a>
                        </div>
                    </div>
                    <h4 className={cx('col-12', styles.callus)}>
                        <a href="tel:1800 444 744" className={styles.contctText}> {content.label_text2prefix.value} <strong>{content.label_textpostfix2.value} </strong></a>
                    </h4>
                </div>
            </div>
        </section>
  );
};

export default HomeBottomGetStarted;