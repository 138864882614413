import React, { Component } from 'react';
import cx from 'classnames';
import styles from "../assets/styles/aboutUs.module.css";
import blankGif from "../assets/images/blank.gif";
//import YouTube from 'react-youtube';
import LazyLoad from "react-lazy-load";

class AboutUs extends Component {

    constructor(props) {
        super(props);
    };

    render() {
        const content = this.props.content, we_featured_in = this.props.otherContent;
        const LeadsCount = this.props.LeadsContent || '46,407';

        if (!content) {
            return null;
        }


        const opts = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0
            }
        };

        return (
            <div>
                {/* <section className={cx(styles.joinHashchingSection, "text-center section")}>
                    <div className={"container"}>
                        <h3>Join over 70,000+ Australians who have trusted HashChing to secure a better deal!</h3>
                    </div>
                </section> */}
                <section className={"section text-center"}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={cx('col-12', 'col-sm-12', 'mr-auto', 'ml-auto', styles.titles)}>
                                {/* <h2 className={cx('black', styles.sectionTitle)}>{content.label_heading.value}</h2> */}
                                {/* <h3 className={cx('light', styles.sectionSubtitle)}>{content.label_description.value}</h3> */}
                                <h4 className={cx('strong', styles.sectionSubtitle)}>Join over 70,000+ Australians who have trusted HFG Marketplace to secure a better deal!</h4>
                            </div>
                            <div className={cx('col-12', 'col-sm-12', 'mr-auto', 'ml-auto', 'text-left', styles.countsMain)}>
                                <div className={'row'}>
                                    <div className={cx('col-lg-3 col-sm-6 col-12', styles.countsDetailMain)}>
                                        <span className={styles.countsDetailIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="45" viewBox="0 0 35 45">
                                                <g fill="none" fillRule="evenodd">
                                                    <g fill="#FF8A23">
                                                        <path d="M4.887 9.688c-.004-.054.822-.527 1.57-1.095l-.37.281A12.383 12.383 0 0 1 8.86 7.31l-.45.186c1.096-.446 2.239-.789 3.412-.946l-.482.063c2.142-.278 4.3-.036 6.44.095.999.06 2.005.105 3.006.033.61-.046 1.224-.12 1.823-.251.578-.126 1.118-.336 1.659-.572.587-.256 1.106-.629 1.598-1.034-.469.39-.179 2.007.132 3.167l1.137 4.241.978 3.652c.158.59.299 1.11.358 1.308-.065.122-.231.34-.136.22-.278.348-.59.665-.949.94l.371-.282c-.37.282-.774.51-1.203.687l.449-.186a7.399 7.399 0 0 1-1.794.46c.16-.022.322-.044.482-.064-1.258.165-2.536.099-3.793.013-1.345-.092-2.684-.219-4.034-.23-1.521-.007-3.062.15-4.529.583-1.47.435-3.004.964-4.204 1.893-.52.402-1.787 1.322-1.758 2.047.034.883.632 1.714 1.794 2.495.501-.472.851-.795 1.052-.97.174-.15.349-.295.534-.432l-.371.282a12.252 12.252 0 0 1 2.702-1.534l-.449.186c1.088-.448 2.23-.79 3.395-.95l-.482.064c1.747-.23 3.506-.104 5.25.023.85.064 1.697.13 2.547.153.953.026 1.886-.031 2.83-.167 1.737-.247 3.462-1.063 4.592-2.416.36-.432.695-.88.965-1.368.12-.214.225-.436.31-.663.135-.379.121-.795.044-1.18-.006-.023-.009-.048-.015-.071-.029-.12-.065-.242-.097-.361l-.514-1.92-.801-2.988-.903-3.37-.833-3.107-.588-2.195-.145-.542c-.245-.915-.948-1.592-1.923-1.717a2.224 2.224 0 0 0-1.677.46 2.85 2.85 0 0 0-.6.66 7.05 7.05 0 0 1-.192.264c.096-.12.187-.24.283-.36-.28.356-.596.674-.958.95l.371-.282c-.352.265-.74.483-1.148.654l.449-.186a7.417 7.417 0 0 1-1.836.47c.16-.02.322-.043.482-.064-1.404.18-2.828.087-4.23-.008-1.51-.106-3.026-.233-4.546-.165-3.318.145-6.613 1.331-9.163 3.462-.38.318-.734.66-1.064 1.026-.13.141-.257.288-.382.435-.149.174-.297.349-.434.535-.375.52 3.245 1.097 3.265 1.252z" />
                                                        <path d="M.654 7.652l.211.79.575 2.144.853 3.186 1.038 3.873L4.47 21.89l1.147 4.281 1.074 4.01.904 3.373.65 2.426c.103.385.202.767.308 1.15l.014.052c.117.435.436.842.834 1.068.384.214.94.302 1.372.165.446-.142.877-.41 1.106-.83.225-.417.312-.878.188-1.341l-.212-.79-.574-2.144-.854-3.186-1.038-3.873-1.137-4.244-1.147-4.282-1.074-4.01-.904-3.372-.65-2.427c-.104-.384-.202-.767-.309-1.15l-.013-.051a1.809 1.809 0 0 0-.834-1.068c-.384-.215-.94-.303-1.372-.165-.446.141-.877.41-1.106.829-.225.421-.311.882-.188 1.341z" />
                                                    </g>
                                                    <path stroke="#FF8A23" strokeLinecap="round" strokeWidth="4" d="M5.415 42.976h27.574" />
                                                </g>
                                            </svg>
                                        </span>
                                        <span className={styles.countsDetail}>
                                            <span className={styles.digit}>{content.label_hashchingstats[0].elements.line1.value}</span>
                                            <span className={styles.digitInfo}>{content.label_hashchingstats[0].elements.line2.value}</span>
                                        </span>
                                    </div>
                                    <div className={cx('col-lg-3 col-sm-6 col-12', styles.countsDetailMain)}>
                                        <span className={styles.countsDetailIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="42" viewBox="0 0 52 42">
                                                <g fill="#FF8A23" fillRule="nonzero">
                                                    <path d="M16.774 17.989v-2.14c3.174 0 5.785-2.61 5.785-5.784S19.948 4.28 16.774 4.28c-3.173 0-5.784 2.61-5.784 5.759.039 3.23 2.594 5.81 5.784 5.81v2.14zm0 0v2.14c-5.554 0-9.997-4.484-10.064-10.064C6.71 4.527 11.237 0 16.774 0 22.312 0 26.84 4.527 26.84 10.065c0 5.537-4.527 10.064-10.065 10.064v-2.14zM36.903 20.425v-2.22a3.957 3.957 0 0 0 3.946-3.947c0-2.118-1.827-3.946-3.946-3.946a3.957 3.957 0 0 0-3.946 3.946c0 2.14 1.796 3.946 3.946 3.946v2.22zm0 0v2.22c-4.608 0-8.387-3.8-8.387-8.387 0-4.601 3.742-8.387 8.387-8.387 4.571 0 8.387 3.816 8.387 8.387 0 4.602-3.741 8.387-8.387 8.387v-2.22zM51.303 39.695c-.052 1.225-1.074 2.175-2.284 2.122-1.209-.052-2.147-1.088-2.095-2.312.297-7.02-3.294-9.403-10.633-9.403-1.21 0-2.192-.994-2.192-2.219 0-1.225.981-2.219 2.192-2.219 9.516 0 15.44 3.931 15.012 14.031zM34.32 39.406c.007 1.226-.968 2.225-2.178 2.233-1.21.008-2.198-.98-2.205-2.205-.045-7.184-3.623-10.872-12.709-10.872-7.595 0-12.752 3.987-12.708 10.844.008 1.225-.967 2.225-2.177 2.233-1.21.008-2.198-.979-2.205-2.204-.062-9.629 7.287-15.31 17.09-15.31 11.52 0 17.031 5.682 17.091 15.281z" />
                                                </g>
                                            </svg>
                                        </span>
                                        <span className={styles.countsDetail}>
                                            <span className={styles.digit}>70,000+</span>
                                            <span className={styles.digitInfo}>{content.label_hashchingstats[1].elements.line2.value}  </span>
                                        </span>
                                    </div>
                                    <div className={cx('col-lg-3 col-sm-6 col-12', styles.countsDetailMain)}>
                                        <span className={styles.countsDetailIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="46" viewBox="0 0 41 46">
                                                <g fill="#FF8A23" fillRule="evenodd">
                                                    <path d="M22.353 41.176l-2.086.853-1.635-.74C9.89 37.426 4.194 29.937 4.194 20.332v-7.692l16.242-9.037L37.13 12.64v7.464c-.056 9.719-5.809 17.265-14.777 21.073zm17.848-29.349c0-.76-.431-1.468-1.13-1.794L21.045.245c-.645-.327-1.399-.327-1.99 0L1.512 10.033c-.7.326-1.076 1.033-1.076 1.794v8.693c0 10.876 6.403 19.564 16.304 23.915l2.421 1.033c.269.163.538.163.807.163.27 0 .538-.054.807-.163l2.744-1.196C33.636 40.03 40.2 31.342 40.2 20.248v-8.42z" />
                                                    <path d="M29.725 17.751L19.147 29.725l-.002-.002v.002l-6.611-7.484 2.644-2.993 3.967 4.49 7.936-8.98z" />
                                                </g>
                                            </svg>

                                        </span>
                                        <span className={styles.countsDetail}>
                                            <span className={styles.digit}>2000+</span>
                                            <span className={styles.digitInfo}>{content.label_hashchingstats[2].elements.line2.value} </span>
                                        </span>
                                    </div>
                                    <div className={cx('col-lg-3 col-sm-6 col-12', styles.countsDetailMain)}>
                                        <span className={styles.countsDetailIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <path fill="none" fillRule="evenodd" stroke="#FF8A23" strokeWidth="4" d="M22.519 35.84l10.813 5.648c.585.305.988.023.895-.634l-1.786-12.616c-.062-.442.133-1.057.435-1.38l8.477-9.072c.455-.487.293-.974-.36-1.09l-11.893-2.1c-.434-.076-.95-.46-1.147-.857L22.34 2.446c-.296-.595-.774-.594-1.07 0L15.659 13.74c-.196.394-.71.78-1.147.857l-11.893 2.1c-.656.117-.815.603-.36 1.09l8.477 9.071c.304.325.497.942.435 1.38L9.385 40.855c-.093.657.307.94.894.634l10.814-5.647c.395-.207 1.032-.206 1.426 0z" />
                                            </svg>

                                        </span>
                                        <span className={styles.countsDetail}>
                                            <span className={styles.digit}>{content.label_hashchingstats[3].elements.line1.value}</span>
                                            <span className={styles.digitInfo}>{content.label_hashchingstats[3].elements.line2.value} </span>
                                        </span>
                                    </div>
                                </div>
                                {/* <section className={"text-center"}>
                                    <a className={cx("btn btn-primary btn-white", styles.btnWhite)} href="/loan-enquiry">Get Started</a>
                                </section> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
};

export default AboutUs;