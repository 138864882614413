
import React, { Component } from "react";
import cx from 'classnames';
import Styles from '../assets/styles/howItWorksSteps.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import newslogos from "../assets/images/newslogos.svg";
import "../assets/styles/broker-hero.css?ver=0.1";
import BrokerFinderSearch from "../components/brokers/brokerFinderSearch";

library.add(faCheck);

class BrokerHero extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // circularAnimation: '',
      // allContents: '', 
    }
  }

  render() {
    var searchData = {};
    searchData.edges = [];
    searchData.edges[0] = {};
    searchData.edges[0].node = {};
    searchData.edges[0].node.elements = {};
    searchData.edges[0].node.elements.simplified = true;
    searchData.edges[0].node.elements.search_textheading = {};
    searchData.edges[0].node.elements.search_placeholder = {};
    searchData.edges[0].node.elements.search_buttontext = {};
    searchData.edges[0].node.elements.search_textheading.value = "Search top rated local brokers";
    searchData.edges[0].node.elements.search_placeholder.value = "Enter postcode or state";
    searchData.edges[0].node.elements.search_buttontext.value = "Find my broker";
    return (
      <section class={"top-banner"}>
        <div class={"container"}>
          <div class={"row"}>
            <div class={"col-md-9"}>
              <div class={"banner-content"}>
                <h4>Marketplace</h4>
                <h1 class={"page-title"}>
                  <span>Search</span>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34.194"
                    height="33.659"
                    viewBox="0 0 34.194 33.659"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <path
                          fill="#1a2040"
                          stroke="#1a2040"
                          strokeWidth="1"
                          d="M0 0H34.194V33.659H0z"
                          data-name="Rectangle 2171"
                          transform="translate(-2 -2)"
                        ></path>
                      </clipPath>
                    </defs>
                    <g data-name="Group 1849" transform="translate(-206.78 -421)">
                      <g data-name="Group 6" transform="translate(208.78 423)">
                        <g clipPath="url(#clip-path)" data-name="Group 5">
                          <path
                            fill="#1a2040"
                            stroke="#1a2040"
                            strokeWidth="1"
                            d="M30.194 16.753L15.1 4.242 9.711 8.706V0h-4.73v12.622L0 16.753l3.114 3.264 1.873-1.553v11.194h4.724V17.314a15.9 15.9 0 01.044-2.8l5.345-4.428 6.528 5.414c.007.494.005 1.089-.006 1.816v12.343h4.405l.016-10.5 1.036.859z"
                            data-name="Path 14"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg> */}
                  <span>ing</span> <span>for a <nobr>better deal?</nobr></span></h1>
                <h2 class={"page-subtitle"}>Find a top-rated independent mortgage broker <nobr>near you.</nobr></h2>
                <BrokerFinderSearch searchKenticoLabel={searchData} />
                {/* <p>Join 100,000+ Australians who have used HFG Marketplace's free service to secure a <nobr>better deal!</nobr></p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
};

export default BrokerHero;
