
import React from 'react'
import cx from 'classnames';
import Styles from '../assets/styles/homeNavlinksMobview.module.css';
import {Link} from 'gatsby';


const HomeNavlinksMobview = props => {
  const content = props.content;

  return(
    <section className={Styles.homeNavlinksSection}>
    <div className={'container'}>
      <div className={'row'}>
        <div className={cx('col-sm-12', Styles.homeNavlinksinnr)}>
          <div className={cx(Styles.homeNavlinksContnt)}>
            <div className={cx('row', Styles.linksrow)}>
              <div className={cx('col-6', Styles.findbrokLink, Styles.linksinfo)}>
              <Link to={content.edges[0].node.elements.header_menu1[0].elements.href.value}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 28 30">
                  <defs>
                    <path id="a" d="M.034.026h13.458v13.458H.034V.026z" />
                    <path id="c" d="M13.082 13.463H0V.013h13.082z" />
                    <path id="e" d="M12.978.032v13.431H.057V.032h12.92z" />
                  </defs>
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(7.019 .68)">
                      <path fill="#ff8a23" d="M13.492 6.756A6.73 6.73 0 1 1 .034 6.755a6.73 6.73 0 0 1 13.458 0" />
                    </g>
                    <g transform="translate(.074 16.153)">
                      <path fill="#ff8a23" d="M13.082 1.835L11.737.013C6.035.224 1.301 5.102.057 10.636c-.326 1.45.79 2.827 2.302 2.827h7.532l3.191-11.628z" />
                    </g>
                    <g transform="translate(14.512 16.153)">
                      <path fill="#ff8a23" d="M12.92 10.636C11.703 5.216 7.138.425 1.59.032L.058 1.835l3.098 11.628h7.463c1.512 0 2.628-1.377 2.303-2.827" />
                    </g>
                  </g>
                </svg>
                <h4 className={Styles.linkTxt}>{content.edges[0].node.elements.header_menu1[0].elements.label.value}</h4>
                </Link>
              </div>
              <div className={cx('col-6', Styles.askExprtLink, Styles.linksinfo)}>
               <a href={content.edges[0].node.elements.header_menu4[0].elements.href.value}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 31 29">
                  <path fill="#ff8a23" fillRule="evenodd" d="M5.94 8.128c-.526 0-.957-.544-.957-1.21 0-.665.43-1.21.957-1.21h16.473c.526 0 .956.545.956 1.21 0 .666-.43 1.21-.956 1.21H5.94zm0 5.172c-.526 0-.957-.544-.957-1.21 0-.665.43-1.21.957-1.21h16.473c.526 0 .956.545.956 1.21 0 .666-.43 1.21-.956 1.21H5.94zm0 5.172c-.526 0-.957-.544-.957-1.21 0-.665.43-1.21.957-1.21H17.36c.527 0 .957.545.957 1.21 0 .666-.43 1.21-.957 1.21H5.94zM4.56.87C2.25.869.36 3.26.36 6.183V18.71c0 2.922-.02 5.314-.02 5.314l.007 3.77c0 .076 0 .114.03.19.15.342.45.532.75.532s.54-.19.72-.456l2.926-4.036 21.401-.233c2.31 0 4.201-2.392 4.201-5.315V5.95c0-2.923-1.89-5.315-4.2-5.315L4.56.87z"></path>
                </svg>
                <h4 className={Styles.linkTxt}>{content.edges[0].node.elements.header_menu4[0].elements.label.value}</h4>
                </a>
              </div>
              <div className={cx('col-4', Styles.compareloneLink, Styles.linksinfo)} style={{display:"none"}}>
              <Link to={content.edges[0].node.elements.header_menu2[0].elements.href.value}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 39 28">
                  <path fill="#ff8a23" fillRule="evenodd" d="M30.852 22.132l-3.388 3.387-1.567 5.82-3.374-.9-1.966 1.959 5.871 1.56c.641.173 1.301-.205 1.474-.844l.001-.002 2.949-10.98zm-4.57-15.331c.219 0 .435.089.611.264.353.352.366.893.014 1.244a.872.872 0 0 1-1.25 0 .863.863 0 0 1 0-1.244.893.893 0 0 1 .625-.264zm0-2.381c-.841 0-1.689.319-2.325.952a3.27 3.27 0 0 0 0 4.617 3.304 3.304 0 0 0 4.637 0 3.27 3.27 0 0 0 0-4.617 3.265 3.265 0 0 0-2.312-.952zm-7.054-2.024h12.368v12.302L15.256 30.97 2.89 18.666l16.34-16.27zm-.61-2.394a1.203 1.203 0 0 0-.731.343L.352 17.82a1.195 1.195 0 0 0 0 1.693L14.406 33.51c.47.467 1.231.467 1.7 0l17.535-17.475c.228-.223.357-.528.359-.846V1.192a1.2 1.2 0 0 0-1.195-1.19H18.737a1.124 1.124 0 0 0-.12 0z"></path>
                </svg>
                <h4 className={Styles.linkTxt}>{content.edges[0].node.elements.header_menu2[0].elements.label.value}</h4>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )

}

  


export default HomeNavlinksMobview;